import { createSlice, current } from "@reduxjs/toolkit";
import { getState, saveState } from "../../utilities/helpers";

type authType = {
  is_loggedIn: boolean;
  is_premium: boolean;
  accessToken: null | string;
  email: string | null;
};

const initialState: authType = {
  is_loggedIn: false,
  accessToken: null,
  email: null,
  is_premium: false,
};

const state = getState("auth", initialState);

export const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    login(state, { payload }) {
      state.is_loggedIn = true;
      state.accessToken = payload.token;
      state.email = payload.email;
      state.is_premium = payload.is_premium;
      saveState("auth", current(state));
    },

    logout(state) {
      state.is_loggedIn = false;
      state.accessToken = null;
      state.is_premium = false;
      state.email = null;
      saveState("auth", current(state));
    },

    updateIsPremium(state, { payload }) {
      state.is_premium = payload.is_premium;
      saveState("auth", current(state));
    },

    setEmail(state, { payload }) {
      state.email = payload.email;
      saveState("auth", current(state));
    },
  },
});

export const { login, logout, setEmail, updateIsPremium } = authSlice.actions;

export default authSlice.reducer;
