import React, { FormEvent, useEffect } from "react";
import Layout from "../hoc/Layout";
import { ButtonLoader } from "../component/Loader";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/store";
import { login } from "../store/slices/authSlice";
import useForm from "../Hooks/useForm";
import FormInput from "../component/FormInput";
import SocialLoginButton from "../component/SocialLoginButton";
import Alert from "../component/Alert";
import useAlert from "../Hooks/useAlert";
import { axiosConfig } from "../utilities/helpers";
import { LoginResponse, LoginProps } from "../utilities/types";

const LOGIN_URL = "/auth/login";
const NAVIGATION_DELAY = 2400;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formState, handleChange } = useForm({
    email: "",
    password: "",
  });

  const { data, loading, error, fetchData, resetData } = useAxiosFetch<
    LoginResponse,
    LoginProps
  >();

  const [alert] = useAlert(data, error);

  useEffect(() => {
    if (data?.user) {
      const { is_premium, email } = data.user;
      const { token } = data;

      setTimeout(() => {
        dispatch(login({ token, is_premium, email }));

        resetData();
        navigate("/dashboard");
      }, NAVIGATION_DELAY);
    }
  }, [data, dispatch, navigate, resetData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name as keyof typeof formState, value);
  };

  const loginUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchData(LOGIN_URL, "POST", axiosConfig(""), formState);
  };

  return (
    <Layout headerBanner={null}>
      <div className="container">
        <Alert content={alert} />
        <div className="main-content">
          <div className="register">
            <div className="register__img"></div>
            <div className="register__form">
              <h3>Login</h3>
              <form className="form" onSubmit={loginUser}>
                <FormInput
                  id="email"
                  type="email"
                  label="Email Address"
                  value={formState.email}
                  onChange={handleInputChange}
                />
                <FormInput
                  id="password"
                  type="password"
                  label="Password"
                  value={formState.password}
                  onChange={handleInputChange}
                />
                <div className="input__group submit">
                  <button
                    type="submit"
                    className="btn btn-pry btn-lrg btn-round btn-block"
                  >
                    {loading ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
                <div className="input__group">
                  <div className="flex flex--between">
                    <p>
                      Have an account already? <a href="/register">Register</a>
                    </p>
                    <p className="text-right">
                      <a href="/forgot-password">Forgot password?</a>
                    </p>
                  </div>
                </div>
                <SocialLoginButton />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
