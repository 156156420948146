import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const toggleMenu = () => {
  const navMenu: HTMLElement = document.querySelector(".navigation ul.nav")!;
  const hamburger: HTMLElement = document.querySelector("div.hamburger")!;
  const body: HTMLElement = document.querySelector("body")!;

  [navMenu, hamburger, body].forEach((itm) => {
    if (itm.classList.contains("active")) itm.classList.remove("active");
    else itm.classList.add("active");
  });
};

// get global state from local storage
export const getState = (stateKey: string, initialState: any) => {
  const cachedState = JSON.parse(localStorage.getItem("app_state") || "{}");
  return cachedState[stateKey] || initialState;
};

// sets local state from local storage
export const saveState = (stateKey: string, updatedState: Function) => {
  const currentAppState = JSON.parse(localStorage.getItem("app_state") || "{}");

  localStorage.setItem(
    "app_state",
    JSON.stringify({
      ...currentAppState,
      [stateKey]: updatedState,
    })
  );
};

export const formatCurrency = (
  amount: number,
  currency: string = "NGN"
): string => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency,
  }).format(amount);
};

// "https://api.betuptip.com/v1",
// "http://127.0.0.1:9600/v1",
export const axiosInstance = (token?: string, param?: string): AxiosInstance =>
  axios.create({
    timeout: 30000,
    baseURL: "https://api.betuptip.com/v1",
    headers: {
      "Content-Type": "application/json",
      "usebetup-token": token,
      params: param,
    },
  });

// https://api.betuptip.com/v1
// http://127.0.0.1:9600/v1
export const axiosConfig = (
  accessToken: string,
  baseURL: string = "https://api.betuptip.com/v1",
  timeout: number = 30000
): AxiosRequestConfig => ({
  baseURL,
  timeout,
  headers: {
    "usebetup-token": accessToken,
    "Content-Type": "application/json",
  },
});
