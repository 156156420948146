import React, { useEffect, useState } from "react";
import Article from "../component/Article";
import Layout from "../hoc/Layout";
import Predictions from "../component/Predictions";
import articleData from "../utilities/app-data/articles.json";
import { useTypedSelector } from "../store/store";
import { TodaysPrediction, TodaysPredictionResponse } from "../utilities/types";
import { PageLoader } from "../component/Loader";
import { axiosInstance } from "../utilities/helpers";
import { AxiosResponse } from "axios";

const TODAYS_URL = "/predictions/todays-predictions";

const Dashboard = () => {
  const { accessToken } = useTypedSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [predictions, setPredictions] = useState<TodaysPrediction[]>([]);

  useEffect(() => {
    const fetchTodaysPredictions = () => {
      setIsLoading(true);
      axiosInstance(accessToken)
        .get<TodaysPredictionResponse>(TODAYS_URL)
        .then(({ data }: AxiosResponse<TodaysPredictionResponse>) => {
          setPredictions(data.predictions);
        })
        .catch((err) => console.error("Error fetching predictions:", err))
        .finally(() => setIsLoading(false));
    };

    fetchTodaysPredictions();
  }, [accessToken]);

  if (isLoading) return <PageLoader />;

  return (
    <Layout headerBanner={false}>
      <div className="container">
        <div className="dashboard">
          <section className="odds">
            <div className="odds__header">
              <h3>
                {predictions.length
                  ? predictions[0]._id === "Basic"
                    ? "Free Predictions"
                    : `${predictions[0]._id} Predictions`
                  : "Free Predictions"}
              </h3>
            </div>

            <div className="tip" id="prediction">
              <h3 className="text-sec">
                {predictions.length
                  ? predictions[0]._id === "Basic"
                    ? "Free Tips"
                    : `${predictions[0]._id} Tips`
                  : "Free Predictions"}
              </h3>

              <div className="tip__block">
                {predictions.length ? (
                  predictions.map((prediction) => (
                    <Predictions key={prediction._id} data={prediction.item} />
                  ))
                ) : (
                  <Predictions data={[]} />
                )}
              </div>
            </div>
          </section>

          <div className="shift">
            {articleData.map((article, index) => (
              <Article title={article.title} key={index}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.content,
                  }}
                />
              </Article>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
