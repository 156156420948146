import React from "react";
import PredictionItem from "./PredictionItem";
import { Prediction } from "../utilities/types";

const Predictions = ({ data }: { data: Prediction[] | undefined }) => (
  <table className="prediction">
    <thead className="prediction__header">
      <tr className="prediction__row">
        <th>Date</th>
        <th className="text-light">Live / Prematch</th>
        <th>Event</th>
        <th>Selection</th>
      </tr>
    </thead>
    {!data?.length ? (
      <tbody className="prediction__body">
        <PredictionItem />
      </tbody>
    ) : null}
    {data?.length ? (
      <tbody className="prediction__body">
        {data?.map((itm, id: number) => (
          <PredictionItem data={itm} key={id} />
        ))}
      </tbody>
    ) : null}
  </table>
);

export default Predictions;
