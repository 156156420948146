import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toggleMenu } from "../utilities/helpers";
import { logoutUser } from "../utilities/userFeature";
import { useTypedSelector } from "../store/store";
import NavbarItem from "./NavbarItem";

const navLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Subscriptions",
    link: "/pricing",
  },
  {
    name: "Register",
    link: "/register",
  },
  {
    name: "Login",
    link: "/login",
  },
  {
    name: "Profile",
    link: "/profile",
  },
];

const Navbar = () => {
  const navigate = useNavigate();
  const { is_loggedIn, is_premium } = useTypedSelector((state) => state.auth);

  const Logout = () => {
    logoutUser();
    navigate("/");
  };

  return (
    <nav className="navigation">
      <div className="container">
        <div className="navigation__wrap">
          <div>
            <div className="navigation__logo">
              <Link to="/">
                <img
                  src={`${window.location.origin}/assets/img/logo.svg`}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div>
            <ul className="nav">
              {!is_loggedIn
                ? navLinks
                    .filter(
                      (itm) =>
                        itm.link !== "/dashboard" && itm.link !== "/profile"
                    )
                    .map((navLink, id: number) =>
                      navLink.link !== "/register" &&
                      navLink.link !== "/login" ? (
                        <NavbarItem
                          key={id}
                          link={navLink.link}
                          name={navLink.name}
                        />
                      ) : (
                        <NavbarItem
                          key={id}
                          link={navLink.link}
                          name={navLink.name}
                          className={
                            navLink.link === "/login"
                              ? "btn btn-pry btn-round"
                              : "btn btn-outline-light btn-round"
                          }
                        />
                      )
                    )
                : navLinks
                    .filter(
                      (itm) => itm.link !== "/login" && itm.link !== "/register"
                    )
                    .map((navLink, id: number) =>
                      is_premium && navLink.link === "/pricing" ? null : (
                        <NavbarItem
                          link={navLink.link}
                          name={navLink.name}
                          key={id}
                        />
                      )
                    )}

              {is_loggedIn && (
                <li className="nav__item">
                  <Link to="#" className="nav__link" onClick={Logout}>
                    Logout
                  </Link>
                </li>
              )}
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
