import React from "react";

const ProfileItem = ({
  title,
  value,
}: {
  title: string;
  value: string | undefined;
}) => (
  <tr className="prediction__row">
    <th>{title}</th>
    <td>{value}</td>
  </tr>
);

export default ProfileItem;
