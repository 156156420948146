import React from "react";

type pageHeaderType = {
  headline: string;
  caption?: string | TrustedHTML;
};

const PageHeader = ({ headline, caption }: pageHeaderType) => (
  <div className="subscription__wrap">
    <div className="subscription__header">
      <h3 className="text-sec">{headline}</h3>
      {caption ? (
        <p
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        ></p>
      ) : null}
    </div>
  </div>
);

export default PageHeader;
