import React, { useEffect, useState } from "react";
import Layout from "../hoc/Layout";
import Btn from "../component/Btn";
import Predictions from "../component/Predictions";
import Newsletter from "../component/Newsletter";
import Blockquote from "../component/Blockquote";
import blockquoteData from "../utilities/app-data/blockquote.json";
import { Link } from "react-router-dom";
import { PredictionResponse, Prediction } from "../utilities/types";
import useAxiosFetch from "../Hooks/useAxiosFetch";
import { PageLoader } from "../component/Loader";
import { axiosInstance } from "../utilities/helpers";
import { AxiosResponse } from "axios";

const FREE_PRED = "/predictions/free";
const WEEKEND_URL = "/predictions/recent/Weekend";
const PREMIUM_URL = "/predictions/recent/Premium";

const Home = () => {
  const [p_loading, setPLoading] = useState(false);
  const [w_loading, setWLoading] = useState(false);
  const [r_weekend, setWeekend] = useState<Prediction[]>([]);
  const [r_premium, setPremium] = useState<Prediction[]>([]);

  const { data, loading } = useAxiosFetch<PredictionResponse, undefined>(
    FREE_PRED,
    "GET"
  );

  useEffect(() => {
    const getWeekend = () => {
      setWLoading(true);
      axiosInstance()
        .get<PredictionResponse>(WEEKEND_URL)
        .then(({ data }: AxiosResponse<PredictionResponse>) =>
          setWeekend(data.predictions)
        )
        .catch((err) => console.error(err))
        .finally(() => setWLoading(false));
    };

    const getPremium = () => {
      setPLoading(true);
      axiosInstance()
        .get<PredictionResponse>(PREMIUM_URL)
        .then(({ data }: AxiosResponse<PredictionResponse>) =>
          setPremium(data.predictions)
        )
        .catch((err) => console.error(err))
        .finally(() => setPLoading(false));
    };

    getWeekend();
    getPremium();
  }, []);

  if (loading || p_loading || w_loading) return <PageLoader />;
  return (
    <Layout headerBanner={true}>
      <section className="about">
        <div className="container">
          <div className="about__wrap">
            <div className="about__content">
              <h3 className="heading my-2">
                <span className="text-sec">Best</span> Football Prediction Site
                <span className="text-sec">Worldwide</span> 2024
              </h3>
              <p className="my-2">
                Seeking a trusted football prediction platform to inform your
                betting choices? Betuptip.com, hailed as the ultimate football
                prediction site globally. Our team of seasoned analysts and
                tipsters offer a comprehensive range of free daily betting
                advice, catering to both high-stakes wagers and secure options.
              </p>
              <ul className="my-3">
                <li>All vistors can only see ur free dily soccer tips.</li>
                <li>All paid members hav full access to our value VIP picks</li>
                <li>
                  Chat us on Whatspp only:{" "}
                  <Link to="https://wa.me/+2347076426518" target="_blank">
                    +2347076426518
                  </Link>
                </li>
              </ul>
              <div>
                <a href="#prediction" className="btn btn-outline-pry">
                  Get predictions
                </a>
                <Btn href="/pricing" className="btn btn-dark">
                  Subscribe
                </Btn>
              </div>
            </div>
            <div className="about__img">
              <img src="assets/img/img3.png" alt="about" />
            </div>
          </div>
          <div className="tip" id="prediction">
            <div className="tip__header">
              <h3 className="text-sec">Free Football Tip</h3>
            </div>
            <div className="tip__flex">
              <div className="tip__block">
                <Predictions data={data?.predictions} />
              </div>
              <div className="tip__preview">
                <img src="assets/img/bettinh-tip.svg" alt="" />
                <Btn
                  href="/pricing"
                  className="btn btn--round btn-outline-dark"
                >
                  view all
                </Btn>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="winner">
        <div className="container">
          <Newsletter />
          <div className="winner__wrap">
            <div className="winner__img">
              <img src="assets/img/img1.svg" alt="img" />
            </div>
            <div className="winner__caption">
              <h3>Be a winner</h3>
              <p>
                Embark on a journey into the realm of football investment, where
                steady profits await you everyday. <br />
                <br />
                Join our team of seasoned experts who deliver carefully curated
                selections with odds ranging from 1.50 to 2.00, ensuring a
                consistent path to profitability in your investments.
              </p>
            </div>
            <div className="winner__img">
              <img src="assets/img/img1.svg" alt="img" />
            </div>
          </div>
        </div>
      </section>
      <div className="feature">
        <div className="container">
          <div className="recent-win">
            {/* <div className="recent-win__blog">
              <div className="blog">
                <div className="blog__heading">
                  <h3>Top Blog Posts</h3>
                </div>
                <div className="blog__wrap-grid">
                  {data?.articles.slice(0, 4).map((itm, id: number) => {
                    const link = `/news-detail/${itm.links.api.self.href.match(
                      /[\d]{8,}/g
                    )}`;
                    return (
                      <div className="blog__item" key={id}>
                        <div className="blog__img">
                          <Link to={link}>
                            <img src={itm.images[0]?.url} alt="images" />
                          </Link>
                        </div>
                        <div className="blog__content">
                          <h3 className="blog__title">
                            <Link to={link}>{itm.headline}</Link>
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
            <div className="recent-win__predictions">
              <div className="tip">
                <div className="tip__header">
                  <h3 className="text-sec">Recent Premium Games</h3>
                </div>
                <div className="tip__flex">
                  <Predictions data={r_premium} />
                </div>
              </div>
            </div>
          </div>
          <div className="recent-win__predictions">
            <div className="tip">
              <div className="tip__header">
                <h3 className="text-sec">Recent Weekend Games</h3>
              </div>
              <div className="tip__flex">
                <Predictions data={r_weekend} />
              </div>
            </div>
          </div>
          <div className="feature__service">
            {blockquoteData.map((itm, id) => (
              <Blockquote title={itm.title} key={id}>
                {itm.content}
              </Blockquote>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
